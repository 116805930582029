<template>
<!-- 日程 -->
<div :style="{'--themecolor':themecolors}" v-if="show_schedule">
    <div class="schedule" id="scroll" @scroll='showOut' ref="schendulScroll">
        <div style="min-height:95%;position: relative;">
            <div class="schedule-top">
                <div class="top">
                    <van-search shape="round" v-model="value" placeholder="请输入讲者或主持姓名" @search="scheduleSearch" />
                </div>
                <div class="tabs" v-if="date_lineShow" :style="{padding:date_line.length>4?'0 0.3rem':'0 0.1rem'}">
                    <span class="tabs_left" v-if="date_line.length>4">
                        <van-icon name="arrow-left" :color="themecolors"/>
                    </span>
                    <span class="tabs_arrow" v-if="date_line.length>4">
                        <van-icon name="arrow" :color="themecolors"/>
                    </span>
                    <div class="swiper mySwiper" ref="myswiper" :key="date_line.length">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide tab-box"  v-for="(i,j) in date_line" :key="j" :class="{'tab-active': i.id == schedule_library_id}" @click="active_meeting(i,j)">
                                <span class="week" v-if="i.type == 1">{{ i.week }}</span>
                                <span class="tab-title" :style="i.type == 1 ? 'margin-top:0.02rem':'font-size:.13rem'">{{i.title}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom" v-if="date_lineShow">
                <van-loading :color="themecolors" :text-color="themecolors" v-if="Loading"/>
                <div class="null" v-else-if="schedule_place_config.length == 0">
                    <div class="null-title">
                        <p>暂无会场</p>
                    </div>
                    <van-empty :image="require('../../assets/attend/none-img.svg')" />
                </div>
                <template v-else> 
                    <div class="bottom_main">
                        <div class="session">
                            <ul class="session-title" >
                                <li v-for="(item,index) in schedule_place_config" :key="index">
                                    <div class="session_venue_title">{{item.venue_title}}</div>
                                </li>
                            </ul>
                            <div :class="{'session-boxpc':ua!=1}" class="session-box" ref="sessionScroll" @scroll='showsession'>
                                    <div class="session-main" v-for="(item,index) in schedule_place_config" :key="index">
                                         <div class="session-item" v-for="(i,j) in item.agendum_info" :key="j">
                                            <div class="pad-box" :class="{'live-conduct':i.live == true}">
                                        
                                                <div class="item-title" :style="{background:themecolors}">
                                                    <div class="title-left">
                                                        <span>{{i.start_time_format}}</span>
                                                        <span class="interval">-</span>
                                                        <span>{{i.end_time_format}}</span>
                                                    </div>
                                                </div>
                                                <div class="item-main">
                                                    <div class="meeting-title" @click="viewDetail(i,item.agendum_info,item)">
                                                     <p :class="{liveshow:i.live && item.studio_info!=''}">{{i.agendum_title}}</p>
                                                    </div>
                                                    <div class="item_speaker" v-if="i.people_library[1] && !i.live">
                                                        <span> <strong>讲者：</strong>  <span v-for="(j,k) in i.people_library[1]" :key="k" class="speaker_name">{{j.name}}</span></span>
                                                    </div>
                                                    <div class="meeting-bottom" v-if="i.live && item.studio_info">
                                                        <div class="live-btn" @click="liveUrl(item)"  >
                                                            <img src="@/assets/schedule/live-conduct.gif" class="live-gif" alt="">
                                                            <span>直播</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </template>
                <div v-if="iscover" class="cover_img">
                    <div class="img_item">
                        <img :src="coverurl">
                    </div>
                    <div class="btns" @click="detailClick">进入详情</div>
                </div>
            </div>
             <div class="date_line_box" v-else>
                <van-loading :color="themecolors" :text-color="themecolors" v-if="Loading"/>
                <div v-else>
                    <van-empty :image="require('../../assets/attend/none-img.svg')" />
                    <div class="date_line-title">
                        <p>暂无日程</p>
                    </div>
                </div>
            </div>
        </div>
        <Support></Support>
    </div>
    <sideBar></sideBar>
</div>
<Newdetail v-else></Newdetail>
</template>
<script>
import Swiper from "swiper";
import { mapState } from 'vuex'
import sideBar from '@/components/sidebar';
import Support from '@/components/support';
import Newdetail from './newdetail.vue'
export default {
    components:{
        sideBar,
        Support,
        Newdetail
    },
    data(){
        return{
            displayindex:0,//显示第几个日程
            ua:0,
            scrollTop:0,
            scrollleft:0,
            value:"",
            unfold:false,
            Loading:false,
            params:{},
            meeting_place_id:null,
            schedule_library_id: null,
            schedule_place_config:[],
            date_line:[],
            date_lineShow:false,
            oldid:null,
            iscover: false,
            coverurl: '',
            arrow_up: false,
            showAttention: false,
            venue:[],//所以的会场
        }
    },
    created(){
        
        this.params = this.$route.query;
        this.get_schedule_library_info_list();
        if(sessionStorage.getItem('user_header') == 1 && localStorage.getItem('wxmessage')){
            this.showAttention = true
        }else{
            this.showAttention = false
        }
    },
    computed: {
        ...mapState(['themecolors', 'show_schedule'])
    },
    watch:{
        show_schedule(val){
            if(val){
                this.$nextTick(()=>{
                    this.$refs.schendulScroll.scrollTop = this.scrollTop
                    if(this.$refs.sessionScroll){
                        this.$refs.sessionScroll.scrollLeft = this.scrollleft
                    }
                    new Swiper(".mySwiper", {
                        slidesPerView: "4",
                        spaceBetween: 12,
                        freeMode: true,
                        observer:true,
                        observeParents:true,
                    });
                })
            }
        },
        date_line(val){
            if(val){
                 this.$nextTick(()=>{
                    new Swiper(".mySwiper", {
                        slidesPerView: "4",
                        spaceBetween: 12,
                        freeMode: true,
                        observer:true,
                        observeParents:true,
                    });
                })
            }
        }
    },
    mounted(){
        if((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
           this.ua=1
        }
    },
    methods:{
        // 监听滚动的位置
        showOut(e){
            this.scrollTop = e.target.scrollTop;
            
        },
        showsession(e){
            this.scrollleft = e.target.scrollLeft;
        },
        active_meeting(val,index) {
            if(val.id!=this.prohibit){
                // this.meeting_place_id = val.meeting_place_id, 
                this.displayindex=index
                this.schedule_library_id = val.id
                this.schedule_place_config=this.date_line[index].scheduleList
                this.dragDistance=0;
                this.prohibit=val.id
            }
        },
        //关注或取消关注日程
        async attentionSchedule(val, index, valIndex){
            if(this.$cookies.isKey('microtoken')){
                this.$toast('请用微信浏览器打开！')
                return
            }
            let res
            if( this.schedule_place_config[index].timeline[valIndex].follow_status == 0 ){
                res = await this.$store.dispatch('add_follow_the_schedule', {
                    id: val.id,
                    site_id: this.$route.query.site_id,
                    page_id: this.$route.query.page_id,
                    module_id: this.$route.query.module_id
                })
            }else{
                res = await this.$store.dispatch('delete_follow_the_schedule', val.id)
            }
            if(res.data.code == 200){
                if(this.schedule_place_config[index].timeline[valIndex].follow_status == 0){
                    this.schedule_place_config[index].timeline[valIndex].follow_status = 3
                }else{
                    this.schedule_place_config[index].timeline[valIndex].follow_status = 0
                }
                this.$toast(res.data.message)
            }else{
                this.$toast(res.data.message)
            }
        },
        liveUrl(item){
            if(!item.studio_info){
                this.$toast({
                  message: '当前会议未添加直播间!',
                });
            }else{
                let obj = {
                    studio_id:item.studio_id,
                    site_id:this.params.site_id,
                    form_id:this.$cookies.get("form_user_id")
                }
                this.$store.dispatch("get_user_form_identity",obj).then(res=>{
                    if(res.data.code == 200){
                        location.href = item.studio_info.studio_url + '?liguserinfo=' + res.data.data.token;
                    }
                    if(res.data.code == 206){
                        location.href = item.studio_info.studio_url;
                    }
                }).catch(err=>{
                    location.href = item.studio_url;
                })
            }
            
        },
        /** 转日期格式 */
        getLocalTime(time) {     
            var now = new Date(time);
            var year=now.getFullYear(); 
            var month=now.getMonth()+1; 
            var date=now.getDate(); 
            var hour=now.getHours(); 
            var minute=now.getMinutes(); 
            var second=now.getSeconds(); 
            return year+"-"+month+"-"+date+" "+hour+":"+minute+":"+second;   
        },
        // 转日期格式
        timestampToTime (time) {
            // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
            let date = new Date(time * 1000) 
            let y = date.getFullYear()
            let MM = date.getMonth() + 1
            MM = MM < 10 ? ('0' + MM) : MM
            let d = date.getDate()
            d = d < 10 ? ('0' + d) : d
            let h = date.getHours()
            h = h < 10 ? ('0' + h) : h
            let m = date.getMinutes()
            m = m < 10 ? ('0' + m) : m
            let s = date.getSeconds()
            s = s < 10 ? ('0' + s) : s
            return MM + '-' + d
        },
        timestampToweek (time) {
            // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
            let date = new Date(time * 1000) 
            let y = date.getFullYear()
            let MM = date.getMonth() + 1
            MM = MM < 10 ? ('0' + MM) : MM
            let d = date.getDate()
            d = d < 10 ? ('0' + d) : d
            let h = date.getHours()
            h = h < 10 ? ('0' + h) : h
            let m = date.getMinutes()
            m = m < 10 ? ('0' + m) : m
            let s = date.getSeconds()
            s = s < 10 ? ('0' + s) : s
            return y + '-' + MM + '-' + d
        },
        getWeek(dateString) {
            let dateArray = dateString.split("-");
            let date = new Date(dateArray[0], parseInt(dateArray[1] - 1), dateArray[2]);
            return "周" + "日一二三四五六".charAt(date.getDay());
        },
        /** 时间段范围 */
        isDuringDate(beginDateStr, endDateStr) {
            var curDate = new Date(),
                beginDate = new Date(beginDateStr),
                endDate = new Date(endDateStr);
            if (curDate >= beginDate && curDate <= endDate) {
                return true;
            }
            return false;
        },
        get_schedule_library_info_list(){
            this.Loading = true;
            this.$store.dispatch("get_schedule_library_info_list",{
                page_id:this.params.site_id,
                // module_id:this.params.module_id,
            }).then(res=>{
                if(res.data.code == 200) {
                    document.title = res.data.data.module_title ? res.data.data.module_title : '轻微站';
                }
                if(res.data.code == 200 && res.data.data.length != 0){
                     this.get_schedule_venue_info_all_list();
                    const todeday = new Date(new Date().toLocaleDateString()).getTime()
                    const endday = new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1
                    this.displayindex = res.data.data.list.findIndex(item => todeday  <= Number(item.title) * 1000 && Number(item.title) * 1000 <= endday)
                    if(this.displayindex == -1){
                        this.displayindex = 0
                    }
                    res.data.data.list.forEach((item)=>{
                        item.scheduleList=[]
                        if(item.type == 1){
                            let week = this.timestampToweek(item.title)
                            item.title = this.timestampToTime(item.title)
                            item['week'] = this.getWeek(week)
                        }
                    })
                    this.date_line = res.data.data.list;
                    if(this.date_line.length>0){
                        this.date_lineShow=true
                    }
                    if(this.date_line[this.displayindex].cover_url){
                        this.iscover = true
                        this.coverurl = this.date_line[this.displayindex].cover_url
                    }else{
                        this.iscover = false
                        // this.coverurl = this.date_line[this.displayindex].cover_url
                    }
                    // this.meeting_place_id = this.date_line[this.displayindex].meeting_place_id
                    this.schedule_library_id = this.date_line[this.displayindex].id
                    this.schedule_place_config=this.date_line[this.displayindex].scheduleList
                    if(this.$refs.myswiper){
                        this.$refs.myswiper.swiper.slideTo(this.displayindex, 0, true);
                    }
                    
                }else{
                    this.Loading=false
                }
            })
        },
        // 获取议程信息
        get_schedule_agendum_info_all_list(){
            this.$store.dispatch("get_schedule_agendum_info_all_list",{
                page_id:this.params.site_id,
                // module_id:this.params.module_id,
            }).then(res=>{
                if (res.data.code == 200) {
                    this.venue.forEach((item1)=>{
                        res.data.data.forEach((item,index)=>{
                            
                            item.forEach((i,ind)=>{
                                i.live = this.isDuringDate(i.start_time  * 1000,i.end_time  * 1000);
                                if(i.live == true){
                                if(item?.studio_info != ""){
                                    this.venue[index].agendum_info.unshift(i)
                                    this.venue[index].agendum_info.splice(ind+1,1)
                                }
                            }
                            })
                            if(item[0]?.venue_id==item1.id){
                                item1.agendum_info=item
                            }
                        })
                    })

                    this.date_line.forEach((item)=>{
                        this.venue.forEach((item2)=>{
                         if(item.id == item2.library_id){
                              item.scheduleList.push(item2)
                        }
                        })
                    })
                    this.Loading = false;
                 }
            })
        },
        // 获取所有场地
        get_schedule_venue_info_all_list(){
            this.$store.dispatch("get_schedule_venue_info_all_list",{
                page_id:this.params.site_id,
                // module_id:this.params.module_id,
            }).then(res=>{
                if(res.data.code == 200) {
                    res.data.data.forEach((item)=>{
                        item.agendum_info = []
                    })
                    this.venue=res.data.data
                    this.venue.forEach((item,index)=>{
                        if(item.status==2){
                            this.venue.splice(index,1)
                        }
                    })
                    this.get_schedule_agendum_info_all_list()
                }
            })
        },
        unfoldClick(item){
            this.schedule_place_config.forEach(i=>{
                i.id != item.id ? i.unfold = false : '';
            })
            item.unfold = !item.unfold;
        },
        scrollLeft(){
            this.scroll_left = $('.session-main').scrollLeft();
        },
        listenerFunction(e) {
            document.addEventListener('scroll', this.scrollLeft, true);
        },
        viewDetail(i,timeline,item){
            let scheduleDate = '';
            this.date_line.forEach(i => i.meeting_place_id == this.meeting_place_id ? scheduleDate = i: '' );
            window.sessionStorage.setItem("scheduleTimelineCurrentItem",JSON.stringify(item))
                this.$router.push({name: 'scheduleInfo', query: {
                    id:i.venue_id,
                }});
            // let scheduleDate = '';
            // this.date_line.forEach(i => i.id == this.schedule_library_id ? scheduleDate = i: '' );
            // window.sessionStorage.setItem("scheduleDate",JSON.stringify(scheduleDate))
            // window.sessionStorage.setItem("scheduleCurrentItemConfig",JSON.stringify(item))
            // window.sessionStorage.setItem("scheduleTimelineCurrentItem",JSON.stringify(i))
            // window.sessionStorage.setItem("scheduleTimeline",JSON.stringify(timeline))
            // if(this.$route.query.singePage){
            //     this.$router.push({name: 'scheduleInfo', query: {
            //         site_id:this.params.site_id,
            //         singePage: 'singePage',
            //         site_url: this.$route.query.site_url,
            //         backtrack: 'backtrack'
            //     }});
            // } else {
            //     this.$store.commit('changge_show_schedule', false)
            //     // this.$router.push(({name:'scheduleInfo',query: this.params}));
            // }
        },
        scheduleSearch(value) {
            let obj = JSON.parse(JSON.stringify(this.$route.query))
            obj.module_id = window.sessionStorage.getItem('module_id')?window.sessionStorage.getItem('module_id'):obj.module_id
            this.$store.commit('changeschedulevalue', value)
            if(this.$route.query.singePage) {
                obj.singePage = 'singePage'
                obj.backtrack = 'backtrack'
                this.$router.push({name:'attend',query:obj});
            } else {
                this.$router.push({name:'attend',query:obj});
            }
        },
        detailClick() {
            this.iscover = false
        }
    },
    beforeDestroy () {
        // let meeting_place_id = null
        // let schedule_library_id = null
        // if(sessionStorage.getItem('meeting_id') && sessionStorage.get('schedule_library_id')) {
        //     meeting_place_id = sessionStorage.getItem('meeting_id')
        //     schedule_library_id = sessionStorage.getItem('schedule_library_id')
        // } 
        // if(sessionStorage.getItem('meeting_place_id') && sessionStorage.getItem('schedule_library_id')) {
        //     sessionStorage.removeItem('meeting_place_id')
        //     sessionStorage.removeItem('schedule_library_id')
        // }
        // sessionStorage.setItem('meeting_place_id', this.meeting_place_id)
        // sessionStorage.setItem('schedule_library_id', this.schedule_library_id)
        //else {
        //     meeting_place_id = this.date_line[index].meeting_place_id
        //     schedule_library_id = this.date_line[index].schedule_library_id
        // }
        document.removeEventListener("scroll", this.listenerFunction);
    },
}
</script>
<style lang='less' scoped>
@Color:var(--themecolor);

/deep/.van-empty,/deep/.van-loading{
    position: absolute;
    top: 28%;
    left:50%;
    transform:translateX(-50%);
}
/deep/.van-empty{
    width: 100%;
    .van-empty__image{
        width: 3rem;
        height: 3rem;
    }
}
/deep/.van-loading {
    top: 50%;
}
.schedule{
    width: 100%;
    height: 100%;
    position: absolute;
    background: #fff;
    overflow: hidden;
    overflow-y: scroll;
    top:0;
    bottom:0;
    left:0;
    .schedule-top{
        background: #fff;
    }
    .top{
        padding: .14rem .16rem;
        width: 100%;
        /deep/.van-search{
            padding: 0px;
            .van-search__content--round{
                border-radius:.3rem;
                background-color:#F7F8FA;
            }
            .van-search__content{
                .van-cell{
                    color: #8F9DAF;
                    .van-icon{
                        font-weight: bold;
                    }
                }
                .van-field__body{
                    .van-field__control{
                        color:#8F9DAF;
                        padding-left: .1rem;
                    }
                }
            }
        }
    }
    .tabs{
        position: relative;
        height: 0.6rem;
        &::-webkit-scrollbar{
            height: 0px;
        }
        .van-icon{
            font-weight:bold;
        }
        .tabs_left{
            left: 0.02rem;
            font-size: .18rem;
            position: absolute;
            top: 0.1rem;
            animation: tabsleft 1s linear infinite;
        }
        .tabs_arrow{
            right: 0.02rem;
            font-size: .18rem;
            position: absolute;
            top: 0.1rem;
            animation: tabsright 1s linear infinite;
        }
         @keyframes tabsleft {
            0% {
              left: 0.02rem;
            }
            50% {
              left:0.08rem;
            }
            100% {
              left:0.02rem;
            }
        }
         @keyframes tabsright {
            0% {
              right: 0.02rem;
            }
            50% {
              right:0.08rem;
            }
            100% {
              right:0.02rem;
            }
        }
        .tab-box{
            width: auto;
            height: .5rem;
            border-radius: .1rem;
            overflow: hidden;
            background: #F7F8FA;
            box-sizing: border-box;
            display: flex;
            padding: .06rem .11rem;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            align-items: center;
            .week{
                font-size: .12rem;
                color: #8F9DAF;
            }
            .tab-title{
                font-size: .15rem;
                color:#1F5129 ;
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
                // font-family: 'DinMedium';
                display: block;
                max-width: 100%;
            }
            &:last-child{
                margin-right: 0;
            }
        }
        
        .tab-active{
            background: @Color;
            .week{
                color: #fff;
            }
            .tab-title{
                color:#fff ;
                // font-family: 'DinBold';
                font-weight: 600;
            }
            
        }
    }
    .bottom{
        padding-top: .16rem;
        background: #f2f6f8;
        .cover_img {
            position: absolute; 
            top: 0;
            width: 100%;
            height: fit-content; 
            background: #fff;
            .img_item {
                // min-height: calc(100vh - 0.3rem);
                // background-color: red;
                img {
                    width: 100%;
                }
            }
            .btns {
                position: fixed;
                bottom: 0.25rem;
                left: 0;
                right: 0;
                margin: 0 auto;
                background-color: rgba(0, 225, 175, 0.6);
                border-radius: .35rem;
                width: 1.8rem;
                height: .35rem;
                line-height: .35rem;
                text-align: center;
                color: #fff;
                font-size: .13rem;
            }
        }
        .null{
            width: 100%;
            height: auto;
            background: #fff;
            border-radius: .10rem;
            margin-bottom: .16rem;
            .null-title{
                padding:0px .16rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: .01rem #F5F6FA solid;
                height: .44rem;
                p{
                    font-size: .18rem;
                    width: 78%;
                    // font-family: 'PingFangBold';
                    font-weight: bold;
                }
            }
            /deep/.van-empty{
                position: static;
                transform: none;
                .van-empty__image{
                    margin: .1rem 0;
                }
            }
        }
        .bottom_main{
            .session{
                width: 100%;
                height: auto;
                border-radius: .1rem;
                // margin-bottom: 16px;
                display: flex;
                .session-title{
                    width: 20%;
                    min-width: 1rem;
                    max-width: 2rem;
                    margin: 0 0.04rem 0 0.04rem;
                    // border-bottom: 1px #F5F6FA solid;
                    li{  
                        border-radius: 0.07rem;
                        word-break: break-word;
                        width: 100%;
                        background: #fff;
                        font-size: 0.14rem;
                        padding: 0 0.05rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height:1.2rem;
                        margin-bottom:.1rem;
                        .session_venue_title{
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 3;
                            overflow: hidden;
                            text-overflow:ellipsis;
                            text-align: center;
                        }
                        &:last-child{
                            margin-bottom: 0px;
                        }
                    }
                    .title-right{
                        display: flex;
                        align-items: center;
                        span{
                            font-size: .12rem;
                            margin-right: .08rem;
                            color: #8F9DAF;
                        }
                    }
                }
                .session-box{
                    width: 100%;
                    overflow-x: scroll;
                    &::-webkit-scrollbar{
                       display: none;
                    }
                    .session-main{
                        display:flex;
                        margin-bottom: .1rem;
                        &::after{
                            content: "";
                            display: block;
                            padding-right:0.1rem;
                            height:1.2rem
                        }
                    .session-item{
                        width: 1.2rem;
                        height: 1.2rem;
                        box-sizing: border-box;
                        margin-left: 0.1rem;
                        flex-shrink:0;
                        .pad-box{
                            user-select:none;
                            border-radius: 0.07rem;
                            width: 100%;
                            height: 100%;
                            overflow: hidden;
                            border: .01rem solid #E1E3EE;
                            // border-radius: 20px;
                            box-shadow: 0px .02rem .06rem #E8ECF1;
                            box-sizing: content-box;
                        }
                        .item-title{
                            width: 100%;
                            height: .33rem;
                            display: flex;
                            font-size:.12rem ;
                            justify-content: center;
                            align-items: center;
                            padding: 0 0.1rem;
                            background: #f5fffe;
                            color: #fff;
                            border-bottom: solid .01rem #e1e2ec;
                            .title-left{
                                display: flex;
                                align-items: center;
                                span{
                                    display: flex;
                                    font-size:.13rem ;
                                    // font-family: 'DinBold';
                                    font-weight: bold;
                                }
                                .interval{
                                    margin: 0px .02rem;
                                }
                                
                            }
                        }
                        .item-main{
                            position: relative;
                            padding: 0.1rem;
                           .meeting-title{
                                height: 0.5rem;
                                p{
                                    font-size: .14rem;
                                    color: #505D6F;
                                    // font-family: 'PingFangBold';
                                    display: -webkit-box;
                                    word-break: break-all;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    white-space: pre-line;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 2;
                                }
                                .liveshow{
                                    -webkit-line-clamp:2;
                                }
                           } 
                            .meeting-bottom{
                                    position: absolute;
                                    bottom: -.03rem;
                                    right: 0;
                                    width: 100%;
                                    display: flex; 
                                    justify-content: flex-end;
                                    .live-btn{
                                       background: #F5FFFE;
                                    //    border: 1px solid #D5D7E4;
                                       width: 100%;
                                       height:.3rem;
                                       border-radius: .2rem;
                                       max-width: .8rem;
                                       margin:0 auto;
                                       display: flex;
                                       align-items: center;
                                       justify-content: center;
                                       span{
                                            font-size: .14rem;   
                                            color: #8F9DAF;
                                            margin-left: .08rem;
                                       }
                                    }
                            }
                            .item_speaker{
                                font-size: .14rem;
                                width: 100%;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow:ellipsis;
                                
                                .speaker_name{
                                    &::after{
                                        content: "，";
                                    }
                                    &:last-child{
                                        &::after{
                                        content: "";
                                        }
                                    }
                                }
                           }
                        }
                    }
                    .live-conduct{
                        border: 1px solid #FF5C60!important;
                        .item-title{
                            background:#fff3f3;
                            border-bottom: 0.01rem solid #FF5C60;
                            // .title-left{
                            //     span{
                            //        color: #FF5C60; 
                            //     }
                            // }
                        }
                        .item-main{
                            .live-btn{
                                    background: #fff2f2 !important;
                                // border: 1px solid #FF5C60;
                                span{
                                    color: #FF5C60 !important
                                }
                            }
                        }
                    }
                    }
                }
                .session-boxpc{
                     width: 100%;
                    overflow-x: scroll;
                    &::-webkit-scrollbar{
                        display: block;
                        height: 0.1rem;
                        width: 100% !important;
                    }
                    &::-webkit-scrollbar-thumb{
                         background: @Color;
                        border-radius: 0.05rem;
                    }
                }
                .session-main-two{
                    padding:.25rem .16rem;
                    .session-item{
                        position: relative;
                        .item-title{
                            display: flex;
                            align-items: center;
                            .title-right{
                                margin-left: .05rem;
                                display: flex;
                                align-items: center;
                                padding: .05rem .14rem;
                                background: #F0F2FA;
                                border-radius: .1rem;
                                span{
                                    display: flex;
                                    font-size:.12rem ;
                                    // font-family: 'DinBold';
                                    font-weight: bold;
                                }
                            }
                        }
                        &::after{
                            content: '';
                            height: 86%;
                            width: .01rem;
                            background: #D5D7E4;
                            position: absolute;
                            top: .24rem;
                            left: .05rem;
                        }
                    }
                    .live-conduct{
                        .item-title{
                            .title-right{
                                background:#fff3f1;
                                span{
                                    color: #FF5C60;
                                }
                            }
                        }
                        .item-main{
                            .item-main-box{
                                border: solid .01rem #FF5C60;
                                .meeting-bottom{
                                    .live-btn{
                                        background: #fff3f3;
                                        // border: 0.5px solid #FF5C60;
                                        span{
                                            color: #FF5C60
                                        }
                                    }
                                }
                            }
                        }
                        &::after{ 
                            background: #ff7174;
                        }
                    }
                }
            }
        }
    }
    .date_line_box{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        background: #fff;
        border-radius: .1rem;
        margin-bottom: .16rem;
        .date_line-title{
            position: absolute;
            bottom: 0.1rem;
            padding:0px .16rem;
            width: 100%;
            // border-bottom: 0.01rem #F5F6FA solid;
            p{
                margin: 0 auto;
                text-align: center;
                font-size: .14rem;
                width: 78%;
                color: #999;
                // font-family: 'PingFangBold';
            }
        }
        /deep/.van-empty{
            position: static;
            transform: none;
            padding: 0;
            .van-empty__image{
                margin: .10rem 0;
            }
        }
    }
}
.live-gif{
    width: .24rem;
    height: auto;
}
.swiper {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
</style>