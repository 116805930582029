import { render, staticRenderFns } from "./brandNewIndex.vue?vue&type=template&id=52bd54ee&scoped=true&"
import script from "./brandNewIndex.vue?vue&type=script&lang=js&"
export * from "./brandNewIndex.vue?vue&type=script&lang=js&"
import style0 from "./brandNewIndex.vue?vue&type=style&index=0&id=52bd54ee&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52bd54ee",
  null
  
)

export default component.exports